import md5 from "js-md5";
import _ from "lodash";
class Utils {
  static encryptPassword(password = "") {
    // 加密盐，不要随意修改
    const salt = "q4Ku!B@-NQcb";
    return md5(password + salt);
  }
  /**
   * UNIX时间戳转字符串
   *
   * @static
   * @param {timeStemp} timeStemp UNIX时间戳
   * @param {boolean} [hasTime=false] 是否显示时间
   * @param {string} [dateSeparator='/'] 日期分隔符,推荐'/'以兼容各浏览器
   * @param {string} [timeSeparator=':'] 时间分隔符
   * @returns string '2017/01/01' | '2017/01/01 00:00:00' | ''
   * @memberof Utils
   */
  static timestampToDateString(
    timeStemp,
    hasTime = true,
    dateSeparator = "-",
    timeSeparator = ":"
  ) {
    if (!timeStemp || !_.isNumber(+timeStemp)) {
      return timeStemp;
    }
    timeStemp = timeStemp.toString().substr(0, 10);
    const time = this.dateToDateString(
      new Date(timeStemp * 1000),
      hasTime,
      dateSeparator,
      timeSeparator
    );
    return time;
  }
  /**
   * 日期对象转字符串
   *
   * @static
   * @param {date} date Date日期对象
   * @param {boolean} [hasTime=false] 是否显示时间
   * @param {string} [dateSeparator='/'] 日期分隔符,推荐'/'以兼容各浏览器
   * @param {string} [timeSeparator=':'] 时间分隔符
   * @returns string '2017/01/01' | '2017/01/01 00:00:00' | ''
   * @memberof Utils
   */
  static dateToDateString(
    date,
    hasTime = false,
    dateSeparator = "-",
    timeSeparator = ":"
  ) {
    if (_.isDate(date)) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const tmp = [];
      tmp.push(year);
      tmp.push(dateSeparator);
      tmp.push(this.fixedString(month + 1));
      tmp.push(dateSeparator);
      tmp.push(this.fixedString(day));
      if (hasTime) {
        tmp.push(" ");
        tmp.push(this.fixedString(hours));
        tmp.push(timeSeparator);
        tmp.push(this.fixedString(minutes));
        tmp.push(timeSeparator);
        tmp.push(this.fixedString(seconds));
      }
      date = tmp.join("");
      return date;
    } else {
      return "";
    }
  }
  /**
   * 格式化字符串,前置补填位符
   *
   * @static
   * @param {any} string 要格式的字符串,为空则全补填位符
   * @param {number} [length=2] 字符串最小长度,超出不处理
   * @param {string} [space='0'] 默认填位符
   * @returns {string} '05'
   * @memberof Utils
   */
  static fixedString(string, length = 2, space = "0") {
    if (string) {
      string = string.toString();
    } else {
      string = "";
    }
    const tmp = [];
    const stringLen = string.length;
    const stringArray = string.split("").reverse();
    if (stringLen < length) {
      _.times(length, (n) => {
        const i = length - n - 1;
        // console.log(length, n, stringLen, i)
        tmp.push(i >= stringLen ? space : stringArray[i]);
      });
      return tmp.join("");
    } else {
      return string;
    }
  }
}
export default Utils;
