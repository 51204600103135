import Vue from "vue";
import Vuex from "vuex";

const files = require.context("./modules", false, /\.js$/);
const modules = {};
files.keys().forEach((element) => {
  const module = files(element).default;
  if (module) {
    const fileName = element.split("/")[1].split(".")[0];
    modules[fileName] = module;
  }
});
Vue.use(Vuex);

export default new Vuex.Store({
  modules,
});
