<template>
  <el-menu
    :mode="mode"
    text-color="#333"
    active-text-color="#020202"
    unique-opened
    router
    style="border-bottom: 0; background: transparent; margin-top: 9px"
    menu-trigger="click"
    @select="menuChange"
  >
    <el-menu-item index="/">{{ $t("menu.index") }}</el-menu-item>
    <el-submenu index="/solution">
      <template slot="title">{{ $t("menu.solution") }}</template>
      <el-menu-item index="/solution/2">{{
        $t("menu.solution2")
      }}</el-menu-item>
      <el-menu-item index="/solution/1">{{
        $t("menu.solution1")
      }}</el-menu-item>
      <el-menu-item index="/solution/3"
        >{{ $t("menu.solution3") }}
      </el-menu-item>
    </el-submenu>
    <el-submenu index="case">
      <template slot="title">{{ $t("menu.case") }}</template>
      <el-menu-item index="/case/2">{{ $t("menu.case2") }}</el-menu-item>
      <el-menu-item index="/case/3">{{ $t("menu.case3") }}</el-menu-item>
      <el-menu-item index="/case/1">{{ $t("menu.case1") }}</el-menu-item>
    </el-submenu>
    <el-menu-item index="/news">{{ $t("menu.news") }}</el-menu-item>
    <el-menu-item index="/about">{{ $t("menu.about") }}</el-menu-item>
  </el-menu>
</template>
<script>
export default {
  name: "com-menu",
  props: {
    mode: String,
  },
  data() {
    return {};
  },
  methods: {
    menuChange() {
      this.$emit("menuChange");
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .el-menu-item,
  .el-submenu__title {
    height: 42px !important;
    line-height: 42px !important;
    font-weight: bold;
    border-radius: 10px;
    border: 0 none !important;
  }
}
</style>
