export default {
  common: {
    title: "Jiugu Digital Technology(Shanghai) Co., Ltd.",
    keywords:
      "Digitworks, Fatigue driving, Active safety, Smart driving, Autonomous driving, Big data, Cloud platform",
    description: "DigitalWorks",
    more: "Detail",
    copyright: "Copyright ©2023 By Jiugu Digital Technology(Shanghai) Ltd",
    ICP: "沪ICP备2021013714号-1",
    back: "Back",
    loading: "Loading",
    releaseTime: "Release Time",
    search: "Search",
    input: "Please Input",
  },
  menu: {
    index: "Index",
    solution: "Solution",
    solution1: "Digital Vehicle Connectivity Cloud Platform",
    solution2: "Regional high - precision safety regulation",
    solution3: "Enterprise Digitization Solutions",
    case: "Client Case",
    case1: "Beidou Fisheries Regulatory Platform",
    case2: "High - precision control of railroad cargo stations",
    case3: "Shanghai Centralized Monitoring of Dangerous Goods",
    news: "News",
    about: "About Us",
    technology: "Technology Accumulation",
  },
  main: {
    p1: "Regional high - precision security management",
    p2: "According to the characteristics of regional closed or semi - closed areas, it focuses on solving problems such as regional maps that cannot be covered and updated in time, or inaccurate regional maps and fast changes in internal structure.Practical application scenarios due to the coexistence of unmanned and manned operations, management requires high positioning accuracy, accurate area maps, and centimeter - level accurate positioning of the operating vehicles to solve the safety problems in the actual operation; high data collection frequency requirements, with a minimum of 1HZ data transmission frequency, in order to effectively track anomalies, carry out early warning calculations and automatically intervene in a timely manner.",
    p3: "Self - developed 3D real - life modeling technology",
    p4: "RTK high - precision positioning device",
    p5: "RTK drone for tilt - shot photography",
    p6: "High - precision map construction based on oblique photography",
    p7: "For high prevention and control or confidential areas, large ports, mines, airports, and electric power wind power places, the drone is used to take tilt photography shots and complete the construction of three - dimensional live view high - precision maps through the self - developed 3D real - view modeling technology and 3D layer services of DigitalWorks.Combined with high - precision positioning hardware and platform computing, it realizes real - time detection and prevention and control of personnel and vehicle operations entering a specific control area, and provides early warning and sends voice prompts for man - vehicle and vehicle - vehicle collisions, and conducts manual or AI intervention.",
    p8: "Large ports",
    p9: "Large mining areas",
    p10: "Airport",
    p11: "Drone Tilt - Up Photography",
    p12: "Through DigitalWorks's self-developed 3D real-view modeling technology and 3D layer service, the construction of 3D real-view high-precision maps is completed to solve the problems of lack of maps, inaccurate maps, and untimely map updates in specific areas.",
    p13: "RTK high - precision positioning device",
    p14: "RTK(Real Time Kinematic), or Carrier Phase Difference technique, which provides real - time, centimeter - level accuracy in three - dimensional positioning results of a survey site in a specified coordinate system.",
    p15: "In the RTK mode of operation, the base station collects satellite data and transmits its observations along with the site coordinate information via a data chain to the mobile station, which, in turn, produces centimeter - level positioning results by performing real - time carrier - phase differential processing(which lasts less than a second) on the collected satellite data and the received data chain.",
    p16: "A perfect combination of vehicle safety and regional high precision.",
    p17: "Enables more precise human and vehicle safety.",
    p18: "Vehicle monitoring",
    p19: "Through the DigitalWorks's high-precision three-dimensional map technology and vehicle networking technology, it realizes the high-precision human-vehicle management within a specific area, and the vehicle operation management outside the area, so as to achieve the synergistic management of inside and outside the area, high-precision and regular-precision positioning in order to realize the demands of the customer management of different management dimensions.",
    p20: "Automated produced Report on operations and safety supervision",
    p21: "The system automatically counts the operation and security event data of different responsible subjects and automatically produces customized reports according to customer needs, providing efficient empowerment for customer's operation management and security management, as well as providing different report texts for different reporting objects, which greatly improves the work efficiency and optimizes the customer's manpower allocation and work costs.",
    p22: "Creating Safe and Intelligent Field Monitoring Solutions.",
    p23: "Enables more precise human and vehicle safety",
    p24: "Rich sensor data access protocol support, open API and data passthrough sharing methods; flexible support for application expansion, and data integration, sharing.",
    p25: "High - precision 3D live map construction based on tilt photography, self - developed 3D modeling and algorithms for point cloud extraction and image texture extraction.",
    p26: "Real - time computation of high - frequency data for massive sensor, business operation, and scheduling data to analyze and mine anomalies for timely warnings and alerts.",
  },
  case: {
    case1Tips: "Creating Safe and Intelligent Field Monitoring Solutions.",
    case1P1:
      "- Connect to China Mobile Communications Corporation's RTK IoT platform to monitor the precise location of the vessel.",
    case1P2:
      "- Fishing Vessel Status Management, calculates the status of fishing vessels in and out of ports, heading, nautical mile speed, and voyage information.It also monitors fishing vessel capsizing events and triggers timely rescue.",
    case1P3:
      "- Fisheries management, fishing vessels are prohibited from leaving port during the ambush season or natural weather such as typhoons.",
    case1P4:
      "- Real - time data passthrough to government fisheries management systems.",
    case2Tips: "Creating Safe and Intelligent Field Monitoring Solutions.",
    case2P1:
      "We introduced advanced RTK drone technology, which provides a new perspective for site monitoring.The tilt photography shooting can capture more details, and combined with our self - developed 3D live modeling technology and 3D layer service, it realizes high - precision live map construction.This will bring brand new application scenarios and solutions to various industries.",
    case2P2:
      "During the monitoring process, our system is able to detect in real time the people and vehicles entering the area, as well as their operations.With the support of intelligent algorithms, we are able to quickly identify unsafe behaviors and abnormalities, providing timely warnings and intervention opportunities for site managers.This not only improves work efficiency, but also strengthens safety management.",
    case2P3:
      "In order to further ensure the safety of people and vehicles, our system is equipped with the function of collision warning between people and vehicles.By monitoring and analyzing data in real time, we are able to warn of potential collision risks in advance and alert relevant personnel in a timely manner through voice prompts.This intelligent early warning system greatly reduces the probability of accidents and protects the safety of people and property.",
    case2P4:
      "Meanwhile, our solution also supports real - time alert function.When human and vehicles enter a specific area, the system is able to send an immediate alert to notify monitors and relevant staff.In this way, we are able to better manage the site and ensure that personnel and vehicles are working at the right time and location, improving overall work efficiency.",
    case2P5:
      "Our field monitoring solutions not only provide comprehensive and accurate data support, but also bring safety and intelligent management to various industries.Whether on construction sites, industrial production sites or road traffic management, our systems can help you achieve remote monitoring, risk warning and workflow optimization.Let's work together to create a safe and efficient working environment.",
    case3Tips: "Creating Safety and Intelligent Field Monitoring Solutions.",
    case3P1:
      "- According to the Shanghai standard data protocol(Shanghai Standard 2019), real - time docking with multiple operators in the jurisdiction to establish an unified district - centered data platform.",
    case3P2:
      "- Provide risk management tools to the Security Risk Control Center for real - time tracking and documentation of key breaches in the jurisdiction.",
    case3P3:
      "- Formulate assessment criteria for transportation enterprises under its jurisdiction, assess compliance requirements, and automatically issue reports to cooperate with municipal traffic control departments to give corrective advice.",
  },
  solution: {
    solution1Tips:
      "Utilizing the mobile Internet and 4G / 5G communication networks to provide location, image, video and other ways of vehicle and personnel management functions, providing real - time scheduling, safety management, data statistics and other services for logistics and transportation, manufacturing and other enterprises.",
    part1Name: "Enterprise Workbench",
    part1Tips: "Customizable Components",
    part2Name: "Real - Time Monitoring and Scheduling",
    part2Tips: "Getting real - time status",
    part3Name: "Industry - based risk modeling for security management",
    part4Name:
      "Automatically Produced Operations and Safety Supervision Report",
    part4Tips: "Obtaining Reports",
    part5Name: "Protocols and functional standards met by the platform",
    standard1:
      "JT / T35658 Technical Requirements for Road Transportation Vehicle Satellite Positioning System Platforms",
    standard2: "Supplementary note on some issues of standard JT / 808",
    standard3:
      "Jiangsu Provincial Local Standard Technical Specification for Road Transportation Vehicle Active Safety Intelligent Prevention and Control System Platform.",
    standard4:
      "JT / T808 Road Transportation Vehicle Satellite Positioning System Terminal Communication Protocol and Data Format.",
    standard5:
      "JT / T 1077 Technical Requirements for Video Platforms for Satellite Positioning Systems for Road Transportation Vehicles.",
    standard6:
      "Jiangsu Provincial Local Standard Communication Protocol Specification for Road Transportation Vehicle Active Safety Intelligent Prevention and Control System.",
    standard7:
      "JT / T809 Data exchange for satellite positioning system platforms for road transport vehicles",
    standard8:
      "Zhejiang Provincial Local Standard Communication Protocol Specification for Road Transportation Vehicle Active Safety Intelligent Prevention and Control System.",
    standard9:
      "Zhejiang Provincial Local Standard Technical Specification for Road Transportation Vehicle Active Safety Intelligent Prevention and Control System Platform.",
    standard10:
      "JT / T1078 Video Communication Protocol for Road Transportation Vehicle Satellite Positioning System.",
    standard11: "Shanghai, Guangdong, Sichuan and other local standards",
    solution2Tips:
      "Vehicle safety, personnel safety, optimization of resource allocation",
    solution2P1: "Three main challenges to regional high precision management",
    solution2P2:
      "Environmental maps do not match reality, map collection and updating is costly and time - consuming, and internal road and facility environments change rapidly.",
    solution2P3:
      "Enclosed / semi - enclosed area with relatively limited location and high positioning accuracy requirements, traditional positioning equipment cannot effectively meet the demand.",
    solution2P4:
      "Provides alerts of approach or entry to hazardous or classified areas, managers and vehicle collision risk and large vehicle and small vehicle collision risk.",
    solution2P5: "3D high - precision live map construction",
    solution2P6: "Drone Tilt - Up Photography",
    solution2P7: "RTK high - precision positioning device",
    solution2P8:
      "RTK(Real Time Kinematic), or carrier - phase differential technology, provides real - time, centimetre - level accuracy in the three - dimensional positioning of a survey site in a specified coordinate system.In the RTK mode of operation, the base station collects satellite data and transmits its observations along with the site coordinate information to the mobile station via a data chain, and the mobile station produces centimeter - level positioning results by performing real - time carrier - phase - difference processing(which lasts less than one second) on the collected satellite data and the received data chain.",
    solution2P9:
      "High Frequency Data Acquisition and Calculation of Collision Warnings",
    solution2P10:
      "Based on high - precision localization of vehicle and personnel position, speed and direction, early warning of collision; high - frequency data collection and real - time calculation, to ensure timely and effective reminder for closed operating areas, yard, temporary objects and other obstacles obscuring the scene, the effect is more prominent.",
    solution2P11: "High Frequency Data Acquisition and Calculation",
    solution2P12:
      "Hazardous area proximity alert, generate platform alerts, automated voice down alerts",
    solution2P13: "Alarm for deviation from the prescribed driving route",
    solution2P14:
      "Early warning, real - time warning, effectively solve the problem of vehicles, people do not follow the scenarios of movement.",
    solution2P15: "Resource planning",
    solution2P16: "GIS Map Measurement Tool",
    solution2P17: "Area, distance measurements",
    solution2P18: "Ground Yard, Building Volume Measurement",
    solution2P19: "Relative Position Pitch, Openness, etc.",
    solution2P20: "Emergency Response",
    solution2P21:
      "Safe management of the distribution of personnel and vehicles to avoid excessive concentration and proximity to hazardous areas.",
    solution2P22:
      "The first time after a disaster occurs, a rescue program is developed based on live maps and the exact location of vehicles and human.",
    solution3Tips:
      "Vehicle safety, human safety, optimization of resource allocation",
    solution3P1: "Stay tuned~",
  },
  news: {
    empty: "No News",
  },
  about: {
    p1: `Jiugu Digital Technology(Shanghai) Co., Ltd.was founded in 2015, is a high - precision positioning platform solutions as the core of the technology service providers, the company adheres to the "to be a digital provider who understands customer and the Internet of Things + high-precision positioning technology services!" The concept of providing professional value services for customers.`,
    p2: `The company launched the "DigitalWorks" brand in 2020, focusing on the development of high - precision map construction, high - precision positioning platform perception algorithms and applications, and has successively launched the vehicle video monitoring and operation platform based on standards such as GB / T35658, JT / T796, JT / T 808 - 2011, JT / T809 - 2011, JT / T1077 - 2016, JT / T - 1078 - 2016, and the regional supervision platform for high - precision positioning, JT / T1077 - 2016, JT / T - 1078 - 2016 and other standards of the vehicle video monitoring operation platform, as well as high - precision positioning regional supervision platform, and provides customers with personalized function customization and development, big data API interface services. The company's core team has more than ten years' experience in platform development and big data services, and participated in the construction of industry local standards in many provinces and cities in China, and has accumulated rich industry experience in big data processing, vehicle networking platform development, three - dimensional map construction, centimeter - level RTK high - precision positioning solutions, and difficult technical problem solving.`,
    p3: `Through consisting efforts, the company has realized good cooperation with customers and is committed to become a pioneer in the IoT + High Precision Positioning and Big Data service industry.`,
    book: "Appointment demo",
  },
};
