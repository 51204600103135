import qs from "qs";
import axios from "axios";
import Utils from "@/utils/utils";
const user = {
  namespaced: true,
  state: {
    token: "",
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },

  actions: {
    // 登录
    Login({ commit, state }) {
      return new Promise((resolve, reject) => {
        const TokenKey = "Auth-Token";
        const storageToken = window.sessionStorage.getItem(TokenKey);
        if (state.token || storageToken) {
          commit("SET_TOKEN", state.token || storageToken);
          resolve();
        } else {
          axios
            .request({
              url: process.env.VUE_APP_BASE_API + "uaa/oauth/token",
              method: "post",
              data: qs.stringify({
                grant_type: "password",
                username: process.env.VUE_APP_USER_NEWS,
                password: Utils.encryptPassword(
                  process.env.VUE_APP_PASSWORD_NEWS
                ),
                client_id: "Wechat",
                client_secret: "Wechat",
              }),
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then((response) => {
              if (response.status === 200 && response.data.access_token) {
                const token = response.data.access_token;
                commit("SET_TOKEN", token);
                window.sessionStorage.setItem(TokenKey, token);
                resolve();
              }
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              //
            });
        }
      });
    },
    logout({ commit }) {
      commit("SET_TOKEN", "");
      const TokenKey = "Auth-Token";
      window.sessionStorage.removeItem(TokenKey);
    },
  },
};

export default user;
