<template>
  <div id="app">
    <el-container>
      <el-header class="clearfix">
        <router-link class="fl" to="/"
          ><img src="@/images/logo.png" class="logo"
        /></router-link>
        <el-select
          v-model="langVal"
          placeholder=""
          size="medium"
          class="fr lang"
        >
          <el-option
            v-for="item in langOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <com-menu class="fr" mode="horizontal" />
        <el-button
          class="fr el-icon-s-unfold"
          type="text"
          @click="drawer = !drawer"
        ></el-button>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer height="80px">
        <p>
          {{ $t("common.copyright") }}
        </p>
        <p>
          {{ $t("common.ICP") }}
        </p>
      </el-footer>
    </el-container>
    <el-drawer title="" :visible.sync="drawer" direction="ltr">
      <router-link to="/" slot="title"
        ><img src="@/images/logo.png" class="logo"
      /></router-link>
      <com-menu mode="vertical" @menuChange="drawer = false" />
      <el-select v-model="langVal" placeholder="" size="medium" class="lang">
        <el-option
          v-for="item in langOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-drawer>
  </div>
</template>

<script>
import comMenu from "@/components/menu/";
export default {
  name: "App",
  data() {
    return {
      drawer: false,
      langVal: "ch",
      langOptions: [
        {
          value: "ch",
          label: "中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],
    };
  },
  components: { comMenu },
  created() {
    this.langVal = localStorage.lang || "ch";
  },
  watch: {
    langVal(value) {
      if (value === "en") {
        // 判断当前语言
        this.$i18n.locale = "en"; // 设置当前语言
      } else {
        this.$i18n.locale = "zn";
      }
      localStorage.lang = value;
      document.title = this.$t("common.title");
      this.drawer = false;
    },
  },
};
</script>
<style scoped lang="scss">
.logo {
  height: 26px;
  margin-top: 15px;
}
.lang {
  margin-left: 40px;
  margin-top: 10px;
  width: 80px;
  position: relative;
  ::v-deep .el-input__inner {
    border: 0 none;
    color: #333;
    background: transparent;
    font-weight: bold;
    padding-left: 0;
  }
  &:before {
    content: " ";
    position: absolute;
    top: 11px;
    left: -30px;
    border-left: 1px solid #ccc;
    height: 16px;
  }
}
.el-footer {
  background: #fafafa;
  text-align: center;
  font-size: 13px;
  padding: 20px;
  height: auto !important;
}

@import "@/styles/mobile.scss";
</style>
