export default {
  common: {
    title: "玖谷数字科技（上海）有限公司",
    keywords:
      "数字动力，digitworks，疲劳驾驶，主动安全，智能驾驶，自动驾驶，大数据，云平台",
    description: "数字动力",
    more: "查看更多",
    copyright: " 版权所有 ©2023 By 玖谷数字科技（上海）有限公司",
    ICP: "沪ICP备2021013714号-1",
    back: "返回",
    loading: "加载中",
    releaseTime: "发布时间",
    search: "查询",
    input: "请输入",
  },
  menu: {
    index: "首页",
    solution: "解决方案",
    solution1: "数动车联云平台",
    solution2: "区域高精度安全监管",
    solution3: "企业数字化解决方案",
    case: "客户案例",
    case1: "北斗渔业监管平台",
    case2: "铁路货运站高精度管控",
    case3: "上海市危险品集中监控",
    news: "咨讯中心",
    about: "关于我们",
    technology: "技术积累",
  },
  main: {
    p1: "区域高精度安全管理",
    p2: "根据区域封闭或半封闭区域的特点，重点解决区域地图无法覆盖、无法及时更新，或区域地图不准确、内部结构变化快等问题；实际应用场景中由于无人操作与有人操作共存，管理上要求定位精度高，区域地图精准，作业车辆达到厘米级精准定位，以解决实际作业中的安全问题；数据采集频率要求高，最低1HZ的数据传输频率，才能有效追踪异常，进行预警计算并自动及时干预。 ",
    p3: "自研3D实景建模技术",
    p4: "RTK高精度定位设备",
    p5: "RTK无人机进行倾斜摄影拍摄",
    p6: "基于倾斜摄影的高精度地图构建",
    p7: "对于高防控或保密区域、大型港口、矿山、机场、电力风力场所，采用无人机进行倾斜摄影拍摄，并通过数字动力的自研3D实景建模技术及3D图层服务，完成三维实景高精度地图的构建。结合高精度定位硬件及平台计算，实现对进入特定管控区域的人员、车辆作业情况进行实时检测及防控，对人车、车车碰撞提前预警并下发语音提示，进行人工或AI干预。 ",
    p8: "大型港口",
    p9: "大型矿区",
    p10: "机场",
    p11: "无人机倾斜摄影",
    p12: "通过数字动力的自研3D实景建模技术及3D图层服务，完成三维实景高精度地图的构建，解决特定区域缺地图、地图不精确、地图更新不及时等问题。",
    p13: "RTK高精度定位设备",
    p14: "RTK (Real Time Kinematic), 即载波相位差分技术，它能够实时地提供测站点在指定坐标系中的三维定位结果，并达到厘米级精度。",
    p15: "在RTK作业模式下，基站采集卫星数据，并通过数据链将其观测值和站点坐标信息一起传送给移动站，而移动站通过对所采集到的卫星数据和接收到的数据链进行实时载波相位差分处理(历时不足一秒)，得出厘米级的定位结果。",
    p16: "车辆安全和区域高精度的完美结合",
    p17: "可以实现更精确的的人车安全",
    p18: "车辆监控",
    p19: "通过数字动力高精度三维地图技术及车联网技术，实现对特定区域内的高精度人车管理，及区域外车辆运营管理，做到区域内外、高精度及常规精度定位协同管理，以实现客户管理不同管理维度的诉求。",
    p20: "自动化生产运营及安全监管报告",
    p21: "系统自动统计不同责任主体的运营及安全事件数据，并根据客户需求，自动生产定制化的报告，为客户的运营管理及安全管理提供高效赋能，同时也可为不同的汇报对象提供不同的报告文本，极大的提升工作效率，优化客户的人力配置及工作成本。",
    p22: "打造安全、智能的现场监测解决方案",
    p23: "实现更精确的的人车安全",
    p24: "丰富的传感器数据接入协议支持，开放的API和数据透传共享方式；灵活支持应用拓展，和数据的整合、共享。",
    p25: "基于倾斜摄影的高精度3D实景地图构建，自研3D建模及点云提取、图像纹理提取算法。",
    p26: "高频数据的实时计算，针对海量传感器、业务运行、调度数据，分析挖掘异常，及时预警和提醒。",
  },
  case: {
    case1Tips: "打造安全、智能的现场监测解决方案",
    case1P1: "- 对接中国移动差分定位物联网平台，对船只精准位置进行监控；",
    case1P2:
      "- 渔船状态管理，计算渔船进出港状态，航向，海里速度，以及航程信息。并对渔船倾覆事件进行监控，及时触发救援；",
    case1P3: "- 渔业管理，伏休期或台风等自然天气渔船禁止出港；",
    case1P4: "- 数据实时透传给政府渔业管理系统。",
    case2Tips: "打造安全、智能的现场监测解决方案",
    case2P1:
      "我们引入了先进的RTK无人机技术，为现场监测提供了全新的视角。倾斜摄影拍摄能够捕捉到更多细节，结合我们自研的3D实景建模技术和3D图层服务，实现了高精度的实景地图构建。这将为各行业带来全新的应用场景和解决方案。",
    case2P2:
      "在监测过程中，我们的系统能够实时检测进入区域的人员和车辆，以及他们的作业情况。通过智能算法的支持，我们能够快速识别不安全行为和异常情况，为现场管理者提供及时的警示和干预机会。这不仅提高了工作效率，也加强了安全管理。",
    case2P3:
      "为了进一步保障人车安全，我们的系统具备人车和车辆之间碰撞预警的功能。通过实时监测和分析数据，我们能够提前预警潜在的碰撞风险，并通过语音提示及时提醒相关人员。这种智能化的预警系统，大大降低了事故发生的概率，保护了人员和财产的安全。",
    case2P4:
      "同时，我们的解决方案还支持实时提醒功能。当人员和车辆进入特定区域时，系统能够立即发出提醒，通知监控人员和相关工作人员。这样一来，我们能够更好地管理现场，确保人员和车辆在合适的时间和位置进行作业，提高整体工作效率。",
    case2P5:
      "我们的现场监测解决方案不仅提供了全面、准确的数据支持，更为各行业带来了安全和智能化的管理方式。无论是在建筑工地、工业生产现场还是道路交通管理中，我们的系统都能够帮助您实现远程监控、风险预警和工作流程优化。让我们携手合作，共同打造一个安全、高效的工作环境。",
    case3Tips: "打造安全、智能的现场监测解决方案",
    case3P1:
      "- 根据上海市标准数据协议（沪标2019），实时对接辖区内多家运营商，建立统一的区中心数据平台；",
    case3P2:
      "- 为安全风控中心提供风险管理工具，对辖区内重点违规事件进行实时追踪、记录；",
    case3P3:
      "- 制定考核标准，对辖区内运输企业，进行合规要求评估，并自动出具报告，配合市交管部门给出整改意见；",
  },
  solution: {
    solution1Tips:
      "利用移动互联网和4G / 5G通讯网络，提供位置、图像、视频等多种方式的车辆、人员管理功能， 为物流运输、生产制造等企业提供实时调度、安全管理、数据统计等服务",
    part1Name: "企业工作台",
    part1Tips: "可自定义组件",
    part2Name: "实时监控及调度",
    part2Tips: "获取实时状态",
    part3Name: "基于行业的风险模型的安全管理",
    part4Name: "自动化生产运营及安全监管报告",
    part4Tips: "获取多维度报告",
    part5Name: "平台满足的协议及功能标准",
    standard1: "JT/T35658道路运输车辆卫星定位系统平台技术要求",
    standard2: "关于对JT/808标准若干问题的补充说明",
    standard3: "江苏省地方标准道路运输车辆主动安全智能防控系统平台技术规范",
    standard4: "JT/T808道路运输车辆卫星定位系统终端通讯协议及数据格式",
    standard5: "JT/T 1077道路运输车辆卫星定位系统视频平台技术要求",
    standard6: "江苏省地方标准道路运输车辆主动安全智能防控系统通讯协议规范",
    standard7: "JT/T809道路运输车辆卫星定位系统平台数据交换",
    standard8: "浙江省地方标准道路运输车辆主动安全智能防控系统通讯协议规范",
    standard9: "浙江省地方标准道路运输车辆主动安全智能防控系统平台技术规范",
    standard10: "JT/T1078道路运输车辆卫星定位系统视频通讯协议",
    standard11: "沪标、粤标、川标等地方标准",
    solution2Tips: "车辆安全、人员安全、资源配置优化",
    solution2P1: "区域精细化管理主要面临的三大难题：",
    solution2P2:
      "环境地图与实际不符，地图采集和更新成本高、时效低，内部道路、设施环境变化快。",
    solution2P3:
      "封闭/半封闭区域，位置相对有限，定位精度要求高，传统定位设备无法有效满足需求。",
    solution2P4:
      "提供危险或机密区域的靠近或进入提醒，管理人员和车辆碰撞风险以及大车辆与小型车辆的碰撞风险。",
    solution2P5: "3D高精度实景地图构建",
    solution2P6: "无人机倾斜摄影",
    solution2P7: "RTK高精度定位设备",
    solution2P8:
      "RTK (Real Time Kinematic),即载波相位差分技术，它能够实时地提供测站点在指定坐标系中的三维定位结果，并达到厘米级精度。在RTK作业模式下，基站采集卫星数据，并通过数据链将其观测值和站点坐标信息一起传送给移动站，而移动站通过对所采集到的卫星数据和接收到的数据链进行实时载波相位差分处理(历时不足一秒)，得出厘米级的定位结果。",
    solution2P9: "高频数据采集及计算碰撞预警",
    solution2P10:
      "基于高精度定位车辆、人员位置、速度以及方向，对碰撞进行预警；高频数据采集和实时计算，保证提醒的及时和有效针对封闭作业区域，堆场、临时物件等障碍物遮挡场景，效果更佳突出。",
    solution2P11: "高频数据采集及计算",
    solution2P12: "危险区域靠近提醒，生成平台警报， 自动语音下发提醒；",
    solution2P13: "规定行驶线路偏离告警；",
    solution2P14:
      "提前预警，实时预警，有效解决车辆、人员不按照规定移动的场景问题。",
    solution2P15: "资源规划",
    solution2P16: "GIS地图测量工具",
    solution2P17: "面积、距离测量",
    solution2P18: "地面堆场、建筑体积测量",
    solution2P19: "相对位置俯仰角度等",
    solution2P20: "应急救援",
    solution2P21:
      "对人员车辆分布进行安全管理，避免过度集中，以及靠近危险区域；",
    solution2P22:
      "灾害发生后第一时间根据实景地图以及车辆人员准确位置，制定救援方案。",
    solution3Tips: "车辆安全、人员安全、资源配置优化",
    solution3P1: "敬请期待~",
  },
  news: {
    empty: "无咨讯内容",
  },
  about: {
    p1: "玖谷数字科技（上海）有限公司成立于2015年，是一家以高精度定位平台解决方案为核心的技术服务商，公司秉承“做一家懂客户的物联网 + 高精度定位技术服务商！”的理念，为客户提供专业价值服务。",
    p2: "公司于2020年推出了“数字动力”品牌，重点开发高精度地图构建、高精度定位平台感知算法及应用，先后推出了基于GB / T35658、JT / T796、JT / T 808 - 2011、 JT / T809 - 2011、JT / T1077 - 2016、JT / T - 1078 - 2016 等标准的车辆视频监控运营平台，以及高精度定位区域监管平台，并为客户提供个性化功能订制与开发、大数据API接口服务。公司核心团队具有超过十年的平台开发与大数据服务经验，并参与了国内多省市的行业地方标准的建设，在大数据处理、车联网平台开发、三 维地图构建、厘米级RTK高精度定位解决方案、疑难技术问题解决等方面，累积了丰富的行业经验。",
    p3: "公司通过不懈努力，与客户实现了良好的合作，并致力成为物联网 + 高精度定位及大数据服务行业的先行者。",
    book: "预约演示：",
  },
};
