<template>
  <el-row class="article">
    <el-col
      :span="8"
      v-for="(item, index) in listConfig"
      :key="type + index"
      :class="type + item.index"
    >
      <router-link :to="item.url + item.index" class="link">
        <span class="num">0{{ index + 1 }}</span>
        <p>{{ $t(item.i18n_title) }}</p>
        <span class="more">
          {{ $t("common.more") }}
        </span>
        <div class="bg"></div>
      </router-link>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "case-list",
  props: {
    type: String,
  },
  data() {
    return {
      solutionConfig: [
        { i18n_title: "menu.solution2", url: "/solution/", index: "2" },
        { i18n_title: "menu.solution1", url: "/solution/", index: "1" },
        { i18n_title: "menu.solution3", url: "/solution/", index: "3" },
      ],
      caseConfig: [
        { i18n_title: "menu.case2", url: "/case/", index: "2" },
        { i18n_title: "menu.case3", url: "/case/", index: "3" },
        { i18n_title: "menu.case1", url: "/case/", index: "1" },
      ],
    };
  },
  computed: {
    listConfig() {
      return this.type === "case" ? this.caseConfig : this.solutionConfig;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/article.scss";
</style>
