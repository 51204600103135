import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import Home from "@/views/home/";
// DHL路由
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/solution/:solutionId",
    name: "solution",
    component: () => import(/* webpackChunkName: "404" */ "@/views/solution/"),
  },
  {
    path: "/case/:caseId",
    name: "case",
    component: () => import(/* webpackChunkName: "404" */ "@/views/cases/"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import(/* webpackChunkName: "404" */ "@/views/news/"),
  },
  {
    path: "/news/:newsId/:beginTime",
    name: "newsDetail",
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/news/article"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "404" */ "@/views/about/"),
  },
];
const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  next();
});
router.afterEach(() => {
  //
});

export default router;
