<template>
  <div class="dashboard" :class="{ en: $i18n.locale === 'en' }">
    <div class="part slogan">
      <h2 class="animation-1">{{ $t("main.p1") }}</h2>
      <p class="center h2-txt animation-2">{{ $t("main.p2") }}</p>
      <div class="center h2-img animation-3">
        <img :src="imgUrl" class="bigImg" />
        <span class="tips tips1" id="tips1"
          ><img src="@/images/dashboard/icon1.png" /> {{ $t("main.p3") }}</span
        >
        <span class="tips tips2" id="tips2"
          ><img src="@/images/dashboard/icon2.png" />{{ $t("main.p4") }}</span
        >
        <span class="tips tips3" id="tips3"
          ><img src="@/images/dashboard/icon3.png" />
          <p style="width: 110px">{{ $t("main.p5") }}</p></span
        >
      </div>
    </div>
    <h3>{{ $t("main.p6") }}</h3>
    <p class="center h3-txt">
      {{ $t("main.p7") }}
    </p>
    <el-row class="center part2" :gutter="30">
      <el-col :span="8">
        <img src="@/images/dashboard/img2.jpg" />
        <p class="center">{{ $t("main.p8") }}</p>
      </el-col>
      <el-col :span="8">
        <img src="@/images/dashboard/img3.jpg" />
        <p class="center">{{ $t("main.p9") }}</p>
      </el-col>
      <el-col :span="8">
        <img src="@/images/dashboard/img4.jpg" />
        <p class="center">{{ $t("main.p10") }}</p>
      </el-col>
    </el-row>
    <el-row class="part3">
      <el-col :span="12">
        <img src="@/images/dashboard/img5.png" />
      </el-col>
      <el-col :span="12">
        <h3>{{ $t("main.p11") }}</h3>
        <p>{{ $t("main.p12") }}</p>
      </el-col>
    </el-row>
    <el-row class="part4">
      <el-col :span="11">
        <h3>{{ $t("main.p13") }}</h3>
        <p>{{ $t("main.p14") }}</p>
        <p style="margin-top: 10px">{{ $t("main.p15") }}</p>
      </el-col>
      <el-col :span="13">
        <img src="@/images/dashboard/img6.png" />
      </el-col>
    </el-row>
    <h3>{{ $t("main.p16") }}</h3>
    <p class="center h3-txt">{{ $t("main.p17") }}</p>
    <el-row class="part5">
      <el-col :span="11">
        <h3>{{ $t("main.p18") }}</h3>
        <p>{{ $t("main.p19") }}</p>
      </el-col>
      <el-col :span="13">
        <img src="@/images/dashboard/img7.png" />
      </el-col>
    </el-row>
    <h3>{{ $t("main.p20") }}</h3>
    <p class="center h3-txt">
      {{ $t("main.p21") }}
    </p>
    <el-row class="center" style="width: 90%">
      <el-col :span="8">
        <img src="@/images/dashboard/img9.png" />
      </el-col>
      <el-col :span="8">
        <img src="@/images/dashboard/img10.png" />
      </el-col>
      <el-col :span="8">
        <img src="@/images/dashboard/img8.png" />
      </el-col>
    </el-row>
    <h3>{{ $t("menu.solution") }}</h3>
    <p class="center h3-txt">{{ $t("main.p22") }}</p>
    <case-list type="solution" />
    <h3>{{ $t("menu.case") }}</h3>
    <p class="center h3-txt">{{ $t("main.p23") }}</p>
    <case-list type="case" />
    <h3>{{ $t("menu.technology") }}</h3>
    <el-row class="center part6">
      <el-col :span="8">
        <img src="@/images/dashboard/icon4.png" />
        <p>{{ $t("main.p24") }}</p>
      </el-col>
      <el-col :span="8">
        <img src="@/images/dashboard/icon5.png" />
        <p>
          {{ $t("main.p25") }}
        </p></el-col
      >
      <el-col :span="8">
        <img src="@/images/dashboard/icon6.png" />
        <p>
          {{ $t("main.p26") }}
        </p>
      </el-col>
    </el-row>
    <el-backtop target="#app" :right="10" :bottom="10"></el-backtop>
  </div>
</template>

<script>
import caseList from "@/components/list.vue";
export default {
  name: "page-dashboard",
  data() {
    return {
      imgIndex: 1,
      imgUrl: require(`@/images/dashboard/p1.png`),
      timer: null,
      timer1: null,
    };
  },
  components: { caseList },
  mounted() {
    setTimeout(() => {
      this.debounce();
      window.addEventListener("scroll", this.debounce);
      this.timer = setInterval(() => {
        this.imgIndex++;
        if (this.imgIndex >= 5) {
          this.imgIndex = 1;
        }
        this.imgUrl = require(`@/images/dashboard/p${this.imgIndex}.png`);
      }, 2000);
    }, 1500);
  },
  methods: {
    showTop() {
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      const boxTop =
        document.getElementsByClassName("animation-3")[0].offsetTop;
      const tips1Top =
        boxTop +
        document.getElementById("tips1").offsetTop +
        document.getElementById("tips1").offsetHeight;
      const tips2Top =
        boxTop +
        document.getElementById("tips2").offsetTop +
        document.getElementById("tips2").offsetHeight;
      const tips3Top =
        boxTop +
        document.getElementById("tips3").offsetTop +
        document.getElementById("tips3").offsetHeight;
      const offsetHeight = document.body.clientHeight + scrollTop;
      if (offsetHeight > tips1Top) {
        document.getElementById("tips1").className = "tips tips1 animation-4";
      }
      if (offsetHeight > tips2Top) {
        document.getElementById("tips2").className = "tips tips2 animation-5";
      }
      if (offsetHeight > tips3Top) {
        document.getElementById("tips3").className = "tips tips3 animation-6";
      }
    },
    debounce() {
      if (this.timer1) {
        clearTimeout(this.timer1);
      }
      this.timer1 = setTimeout(this.showTop, 50); // 简化写法
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    clearTimeout(this.timer1);
    this.timer1 = null;
    window.removeEventListener("scroll", this.debounce);
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/dashboard.scss";
</style>
